import {
  Box,
  ConditionalWrapper,
  Flex,
  HStack,
  HideWithCSS,
  Image,
  Link,
  VisuallyHidden,
} from "@biblioteksentralen/react";
import { useIsFrontPage } from "../../../utils/hooks/useIsFrontPage";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { LocalizedNextLink } from "../../LocalizedNextLink";
import { useCommonData } from "../CommonDataProvider";
import { FormattedSiteName } from "./FormattedSiteName";
import { imageUrlBuilder } from "@libry-content/common";

function Logo() {
  const { t } = useTranslation();
  const isOnFrontPage = useIsFrontPage();

  return (
    <ConditionalWrapper wrapper={(props) => <h1 {...props} />} condition={isOnFrontPage}>
      <Link
        display="block"
        role="group"
        variant="plain"
        _hover={{ textDecoration: "none" }}
        as={LocalizedNextLink}
        href="/"
      >
        {!isOnFrontPage && <VisuallyHidden>{t("Forsiden")}</VisuallyHidden>}
        <Flex translate="no" flexShrink={0}>
          <HideWithCSS below="lg" width="100%">
            <DesktopLogo />
          </HideWithCSS>
          <HideWithCSS above="lg">
            <MobileLogo />
          </HideWithCSS>
        </Flex>
      </Link>
    </ConditionalWrapper>
  );
}

const DesktopLogo = () => {
  const { site } = useCommonData();
  const showLogoText = !site?.logoContainsName || !site?.logo?.asset;
  const palette = useSitePalette();

  return (
    <HStack spacing="1rem" justifyContent="space-between">
      <HStack spacing="1rem">
        {site?.logo?.asset && (
          <Box
            display={{ base: "none", sm: "block" }}
            filter={!palette.colors.header.isBright ? "invert(1)" : undefined}
          >
            <Image height="2.75rem" src={imageUrlBuilder(site?.logo)?.height(75).url() ?? ""} alt="" />
          </Box>
        )}
        <ConditionalWrapper wrapper={VisuallyHidden} condition={!showLogoText}>
          <FormattedSiteName />
        </ConditionalWrapper>
      </HStack>
    </HStack>
  );
};

const MobileLogo = () => {
  return <FormattedSiteName />;
};

export default Logo;
